

















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Playlist, TrackListItem} from '@/types';
import {PlaylistService, TrackService} from "@/api";
import UBlock from "@/ui-lib/block/Block.vue";
import UButton from "@/ui-lib/button/Button.vue";
import TrackTableList from "@/components/track/TrackTableList.vue";
import {TableField} from '@/ui-lib/table/Table.vue';
import {TracksSelectModalParams} from "@/components/modals/TracksSelectModal.vue";

@Component<PlaylistItemTracksView>({
  components: {
    TrackTableList,
    UBlock,
    UButton,
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const {list, total} = await TrackService.list({
        start: 0,
        limit: 99999,
        order_column: 'pl_pos',
        order_dir: 'asc',
        playlistID: parseInt(to.params.id, 10),
      });

      next((vm) => {
        vm.tracks = list;
        vm.total = total;
      });
    } catch (e) {
      next(e);
    }
  },
})
export default class PlaylistItemTracksView extends Vue {
  @Prop({required: true})
  private playlist!: Playlist;

  private total = 0;
  private tracks: TrackListItem[] = [];
  private isLoading = false;

  private selected: number[] = [];

  private get tableFields(): TableField[] {
    return [
      {
        name: 'select',
        label: '',
        width: '52px',
      },

      {
        name: 'play-pause',
        label: '',
        width: '54px',
      },

      {
        name: 'id',
        label: 'ID',
        width: '65px',
      },

      {
        name: 'title',
        label: 'Название',
      },

      {
        name: 'likes',
        label: 'Лайки',
        width: '85px',
        align: 'right',
      },

      {
        name: 'dislikes',
        label: 'Дизлайки',
        width: '110px',
        align: 'right',
      },

      {
        name: 'listeners_per_month',
        label: 'Слуш/мес',
        width: '115px',
        align: 'right',
      },

      {
        name: 'skips_per_month',
        label: 'Скип/мес',
        width: '115px',
        align: 'right',
      },

      {
        name: 'compilations',
        label: 'Подборки',
        width: '250px',
      },

      {
        name: 'controls',
        label: '',
        align: 'right',
        width: '50px',
      }
    ];
  }

  private showSelect() {
    const ids: number[] = [];

    const params: TracksSelectModalParams = {
      selected: ids,
      excludePlaylistID: this.playlist.id,
      onSelect: async () => {
        this.isLoading = true;

        try {
          const pl = await PlaylistService.attach(this.playlist.id, ids);
          await this.updateList(pl);

          this.$notify({
            type: 'success',
            text: 'Плейлист обновлен',
          });
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: e?.message || 'Неизвестная ошибка',
          });
        }

        this.isLoading = false;
      },
    };

    this.$modal.show('tracks-select', params);
  }

  public async updateList(pl: Playlist) {
    this.isLoading = true;

    try {
      const {list, total} = await TrackService.list({
        start: 0,
        limit: 99999,
        order_column: 'pl_pos',
        order_dir: 'asc',
        playlistID: parseInt(this.$route.params.id, 10),
      });

      this.tracks = list;
      this.total = total;

      this.playlist.totalTracks = pl.totalTracks;
      this.playlist.totalDuration = pl.totalDuration;
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: e?.message || 'Неизвестная ошибка',
      });
    }

    this.isLoading = false;
  }

  private detachItem(track: TrackListItem) {
    if (!confirm(`Удалить трек «‎${track.title}»‎ из плейлиста «‎${this.playlist.title}»‎?`)) {
      return;
    }

    this.detachIDs([track.id]);
  }

  private async detachIDs(ids: number[]) {
    this.isLoading = true;

    try {
      const pl = await PlaylistService.detach(this.playlist.id, ids);
      await this.updateList(pl);

      ids.forEach((id) => {
        const i = this.selected.indexOf(id);

        console.log(i);

        if (i !== -1) {
          this.selected.splice(i, 1);
        }
      });

      this.$notify({
        type: 'success',
        text: 'Плейлист обновлен',
      });
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: e?.message || 'Неизвестная ошибка',
      });
    }

    this.isLoading = false;
  }

  private selectDelete() {
    if (!confirm(`Удалить треки (${this.selected.length}) из плейлиста «‎${this.playlist.title}»‎?`)) {
      return;
    }

    this.detachIDs([...this.selected]);
  }
}
